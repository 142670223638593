<script setup lang="ts">
import { ref } from '#imports'
import type { Placement } from '@floating-ui/vue'
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue'
import { onClickOutside } from '@vueuse/core'

import type { Ref } from 'vue'
import { inject } from 'vue'

const props = defineProps<{
  offset?: number
  placement?: Placement
}>()

const emit = defineEmits(['click-outside'])
const anchor = inject<Ref<Element>>('anchor')

const menuRef = ref<HTMLElement>()

const { isPositioned, floatingStyles } = useFloating(anchor!, menuRef, {
  placement: props.placement || 'bottom-end',
  middleware: [flip(), offset(props.offset || 0), shift({ padding: 8 })],
  whileElementsMounted: autoUpdate,
})

onClickOutside(menuRef, (e) => {
  emit('click-outside', e)
})
</script>

<template>
  <div
    ref="menuRef"
    v-bind="$attrs"
    class="z-100 card bg-surface transform-gpu rounded-md shadow-md transition-opacity duration-300"
    :class="{
      'opacity-0': !isPositioned,
    }"
    :style="floatingStyles"
  >
    <div class="w-max max-w-sm">
      <slot />
    </div>
  </div>
</template>
